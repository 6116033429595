<template>
  <div class="funds-box">
    <div>
      <TextScroll :isVis="isVis" :dataList="textArr" @handlerNotice="getAnnouncement"></TextScroll>
    </div>
    <div>
      <div class="ic-box">
        <div class="item-box">
          <div class="item">
            <div class="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-xxl"
                viewBox="0 0 512 512"
                role="img"
              >
                undefined
                <path
                  fill="var(--ci-primary-color, currentColor)"
                  d="M247.759,14.358,16,125.946V184H496V125.638ZM464,152H48v-5.946L248.241,49.642,464,146.362Z"
                  class="ci-primary"
                ></path>
                <path
                  fill="var(--ci-primary-color, currentColor)"
                  d="M16,496H496V392H16Zm32-72H464v40H48Z"
                  class="ci-primary"
                ></path>
                <rect
                  width="32"
                  height="160"
                  x="72"
                  y="208"
                  fill="var(--ci-primary-color, currentColor)"
                  class="ci-primary"
                ></rect>
                <rect
                  width="32"
                  height="160"
                  x="408"
                  y="208"
                  fill="var(--ci-primary-color, currentColor)"
                  class="ci-primary"
                ></rect>
                <rect
                  width="32"
                  height="160"
                  x="184"
                  y="208"
                  fill="var(--ci-primary-color, currentColor)"
                  class="ci-primary"
                ></rect>
                <rect
                  width="32"
                  height="160"
                  x="296"
                  y="208"
                  fill="var(--ci-primary-color, currentColor)"
                  class="ci-primary"
                ></rect>
              </svg>
            </div>
            <div>{{ $t("Funds.WireTransfer") }}</div>
            <div class="item-btn-box">
              <div @click="toInwardrem()">
                <el-button
                  style="padding-top: 0; color: #00bea4; outline: 0;padding-bottom:4px"
                  type="text"
                  class="hbtn"
                  >{{ $t("Funds.AddFunds") }}</el-button
                >
              </div>
              <div @click="toBankWithdrawal()">
                <el-button
                  style="padding-top: 0; color: #00bea4; outline: 0;padding-bottom:4px"
                  type="text"
                  class="hbtn"
                  >{{ $t('Funds.Withdraw') }}</el-button
                >
              </div>
            </div>
          </div>
         <!-- <div class="item">
            <div class="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-xxl"
                viewBox="0 0 32 32"
                role="img"
              >
                undefined
                <path
                  fill="#3C4B64"
                  d="M25.609 2h-19.217c-2.428 0-4.391 1.963-4.391 4.391v19.217c0 2.428 1.963 4.391 4.391 4.391h19.217c2.408 0 4.36-1.942 4.391-4.35-2.873-1.601-6.912-3.771-10.725-5.527-2.004 2.748-5.258 5.063-9.289 5.063-4.412 0-5.858-2.831-6.065-4.773-0.248-2.438 0.93-5.094 6.22-5.094 2.211 0 4.959 0.641 7.946 1.56 1.033-1.881 1.653-3.771 1.653-3.771h-11.137v-1.044h5.755v-1.953h-6.84v-1.188h6.84v-3.151h3.183v3.151h6.84v1.188h-6.841v1.953h5.548c0 0-0.95 2.914-2.397 5.682 3.058 1.044 6.25 2.252 9.289 3.296v-14.651c0.010-2.418-1.953-4.391-4.371-4.391zM4.955 20.184c0.062 1.261 0.641 3.358 4.371 3.358 3.254 0 5.786-2.48 7.367-4.556-2.789-1.167-5.28-1.963-6.84-1.963-4.216 0-4.96 2.066-4.897 3.161z"
                ></path>
              </svg>
            </div>
            <div>{{ $t("Funds.AliPay") }}</div>
              <div class="">
               <div @click="toAliPay()">
                <el-button
                  style="padding-top: 0; color: #00bea4; outline: 0;padding-bottom:4px"
                  type="text"
                  class="hbtn"
                  >{{ $t("Funds.TransforFunds") }}</el-button
                >
              </div> 
              <div @click="toAliPayWithdrawal()">
                <el-button
                  style="padding-top: 0; color: #00bea4; outline: 0;padding-bottom:4px"
                  type="text"
                  class="hbtn"
                  >{{ $t('Funds.Withdraw') }}</el-button
                >
              </div>
            </div>
          </div>-->
          <div class="item">
            <div class="icon">
              <img style="width:5rem" src="../../assets/USDT.svg" alt="">
            </div>
            <div>USDT</div>
            <div class="item-btn-box">
              <div @click="toTransferUSDT()">
                <el-button
                  style="padding-top: 0; color: #00bea4; outline: 0;padding-bottom:4px"
                  type="text"
                  class="hbtn"
                  >{{ $t("Funds.TransforFunds") }}</el-button
                >
              </div>
              <div @click="toWithdrawUSDT()">
                <el-button
                  style="padding-top: 0; color: #00bea4; outline: 0;padding-bottom:4px"
                  type="text"
                  class="hbtn"
                  >{{ $t('Funds.Withdraw') }}</el-button
                >
              </div>
            </div>
          </div>
          
        </div>
      </div>
      <div class="link">
          <a target="_blank" style="color: #1675D4;" :href="`${ori}/user-guide/${getLa}/accountcz/Explain.html`">{{ $t('Funds.Guide') }}</a>
        </div>
      <div class="tb-box">
        <CRow>
          <CCol sm="12">
            <RecentDeposits
              :items="data"
              hover
              striped
              border
              small
              fixed
              dark
              @searchData="initData"
              :pages="pages"
              caption="Combined All dark Table"
            />
          </CCol>
        </CRow>
      </div>
    </div>
  </div>
</template>
<script>
import RecentDeposits from "../../components/RecentDeposits.vue";
import { getTransctionDetail, getFundAccount, getPageNotice } from "../../api/http";
import TextScroll from "../../components/TextScroll.vue"
export default {
  data() {
    return {
      pages: 0,
      data: [],
      funds_data: "",
      ori: "",
      textArr: [
        // {
        //   id: 1,
        //   val: "资金-资金"
        // }
      ],
      isVis: false,
      isCloseFunds: true,
    };
  },
  created() {
    this.initData();
    this.ori = window.location.origin
  },
  computed: {
    getLa() {
      // return localStorage.getItem("lang")
      let la = this.$i18n.locale
      if(la == 'en') {
        return 'en-US'
      }else {
        return this.$i18n.locale
      }
    }  
  },
  methods: {
    // 请求通告数据
    getAnnouncement(noticeType) {
      let obj = {
        uid: window.localStorage.getItem("uid"),
        type: "get_page_notice",
        // page: "fund_fund"
        page: noticeType
      }
      getPageNotice(obj).then((res) => {
        console.log("notice==>", res)
        if(res.code == 200) {
          if(res.data.length !== 0) {
            this.isVis = true
            this.textArr = res.data
          }else {
            this.isVis = false
          }
        }
      })
    },
    toInwardrem() {
      if(this.isCloseFunds) {
        this.$message.warning(this.$t('Funds.CloseFundsTip'))
        return
      }
      this.$router.push("/funds/inwardrem");
    },
    toAliPay() {
      this.$router.push("/funds/alipay");
    },
    toBankWithdrawal() {
      if(this.isCloseFunds) {
        this.$message.warning(this.$t('Funds.CloseFundsTip'))
        return
      }
      this.handerStatus()
    // this.$router.push("/funds/bankwithdrawal");
    },
    toAliPayWithdrawal() {
      this.$router.push("/funds/alipaywithdrawal");
    },
    toTransferUSDT() {
      this.$router.push("/funds/transfer-usdt")
    },
    toWithdrawUSDT() {
      this.$router.push("/funds/withdraw-usdt")
    },
    // 请求资金账户状态
    handerStatus() {
      let data = {
        type: "get_fund_account",
        uid: window.localStorage.getItem("uid"),
      };
      getFundAccount(data).then((res) => {
        console.log("res==>", res);
        if (res.data.length == 0) {
          this.$confirm(
            this.$t("Funds.Inwardrem.TipsContent"),
            this.$t("Funds.Inwardrem.Tips"),
            {
              confirmButtonText: this.$t("Funds.Inwardrem.TipsButton2"),
              showClose: false,
              showCancelButton: false,
              closeOnClickModal: false,
              type: "warning",
            }
          )
            .then(() => {
              this.$router.push("/funds/accounts");
            })
            .catch(() => {
              // this.$message.error(e)
            });
        } else if (res.data[0].status !== "Complete") {
          this.$confirm(this.$t("Funds.Inwardrem.TipsContent2"), "", {
            confirmButtonText: this.$t("Funds.Inwardrem.TipsButton2"),
            showClose: false,
            showCancelButton: false,
            closeOnClickModal: false,
            type: "warning",
          })
            .then(() => {
              this.$router.push("/funds/accounts");
            })
            .catch(() => {});
        } else {
          this.$router.push("/funds/bankwithdrawal");
          this.funds_data = res.data[0];
        }
      });
    },
    // 初始化
    initData(before, after, card_number, page) {
      let data = {
        type: "get_account_transaction",
        uid: window.localStorage.getItem("uid"),
        before: before || "",
        after: after || "",
        card_number: card_number || "",
        atype: "Deposit",
        page: page || 1,
        page_size: 20,
      };
      getTransctionDetail(data).then((res) => {
        // console.log('detail res==>', res)
        this.pages = Number(res.data.size);
        this.data = res.data.data;
      });
    },
  },
  components: {
    RecentDeposits,
    TextScroll
  },
};
</script>
<style scoped>
.link{
  padding-left: 165px;
  padding-bottom: 10px;
}
.hbtn:hover{
  border-bottom: 1px solid rgb(0,190,164);
  transition: border 1ms linear;
}
.funds-box {
  padding-left: 2.6rem;
}
.icon {
  width: 5rem;
}
.item-box {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 3rem 0;
}
.item {
  text-align: center;
  font-size: 14px;
  width: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.item-btn-box {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
</style>