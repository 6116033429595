<template>
  <div>
      <div class="rcbox">
      <div style="background:#fff">
          <div style="font-weight: bold;font-weight: bold;margin-left:.5rem">{{ $t('Funds.Table.TransferInRecord') }}</div>
      </div>
    <el-table stripe :show-header="true" height="250" style="margin:0 auto;background: none" :data="items">
      <template slot="empty">
              <div style="margin: 3rem 0">
                <h2>
                No items
                <CIcon name="cilBan" style="color:#e53535;width:30px;height:30px" />
              </h2>
              </div>
      </template>
      <el-table-column prop="create_time" :label="$t('Funds.Table.Time')"> </el-table-column>
      <el-table-column prop="amount" :label="$t('Funds.Table.Amount')">
        <template slot-scope="scope">
                <div :class="scope.row.amount > 0 ? 'z' : 'f'" style="font-weight: bold">${{ formatNum(scope.row.amount) }}</div>
        </template> 
        </el-table-column>
      <el-table-column prop="pay_way" :label="$t('Funds.Table.FundingSource')"> </el-table-column>
      <el-table-column prop="status" :label="$t('Funds.Table.Status')">
        <template slot-scope="scope">
          <CBadge :color="getBadge(scope.row.status)">{{ scope.row.status }}</CBadge>
        </template>
      </el-table-column>
    </el-table>
    <div>
        <el-pagination
      background
      layout="prev, pager, next"
      :total="pages"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="20"
      @current-change="handerPage"
    >
    </el-pagination>
    </div>
    
    <!-- <CCard>
      <CCardHeader>
        <slot name="header">
          <div style="padding-bottom: 0.5rem;font-weight: bold;">Recent Deposits</div>
        </slot>
      </CCardHeader>
      <CCardBody>
          
        <CDataTable
          :hover="hover"
          :striped="striped"
          :small="small"
          :fixed="fixed"
          :items="items"
          :fields="tableFields"
          :items-per-page="20"
          :dark="dark"
          pagination
        >
          <template #status="{ item }">
            <td>
              <CBadge :color="getBadge(item.status)">{{ item.status }}</CBadge>
              <div style="color:#fff;padding-top:5px"><CButton size="sm" v-if="item.status == 'Decline'" block color="secondary" variant="outline">原因</CButton></div>
            </td>
          </template>

          <template #amount="{ item }">
              <td>
                  <div :class="item.amount > 0 ? 'z' : 'f'" style="font-weight: bold">${{ formatNum(item.amount) }}</div>
              </td>
          </template>
        </CDataTable>
      </CCardBody>
      <CCardFooter>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="pages"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="20"
          @current-change="handerPage"
        >
        </el-pagination>
      </CCardFooter>
    </CCard> -->
    </div>
  </div>
</template>

<script>
import { CDropdown } from "@coreui/vue";
import { Shopping } from "@icon-park/vue";
import "@icon-park/vue/styles/index.css";
import { CModal, CModalBody, CModalHeader, CModalFooter } from "@coreui/vue";
export default {
  name: "Table",
  props: {
    items: Array,
    fields: {
      type: Array,
      default() {
        return [
          { key: "Time", label: "時間", _style: "vertical-align: middle; " },
          { key: "Amount", label: "金額", _style: "" },
          { key: "pay_way", label: "轉入方式", _style: "" },
          { key: "Status", label: "狀態", _style: "" },
        ];

        // return ['卡号', '持卡人', '有效期', '安全码', '余额', '已消费', '状态', '创建时间', '操作']
      },
    },
    caption: {
      type: String,
      default: "Table",
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean,
    pages: Number,
  },
  data() {
    return {
      visibleVerticallyCenteredDemo: false,
      flat: false,
      forms: {
        beforeDate: "",
        afterDate: "",
        card_number: "",
        atype: "",
      },
    };
  },
  methods: {
    // 切换页码
    handerPage(e) {
      console.log("page e==>", e);
      this.$emit(
        "searchData",
        this.forms.beforeDate,
        this.forms.afterDate,
        this.forms.card_number,
        e
      );
    },
    formatNum(value) {
      if (!value && value !== 0) return 0;

      let str = value.toString();
      let reg =
        str.indexOf(".") > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(?:\d{3})+$)/g;
      return str.replace(reg, "$1,");
    },
    getBadge(Status) {
      return Status === "Complete"
        ? "success"
        : Status === "Pending"
        ? "info"
        : Status === "Decline"
        ? "danger"
        : "primary";
    },
    fotmastStr(str) {
      return str.replace(/^(\d{4})\d+(\d{4})$/, "****$2");
    },
  },
  computed: {
    tableFields() {
      const fields = [
        {
          key: "create_time",
          label: this.$t("Funds.Table.Time"),
          _style: "vertical-align: middle; ",
        },
        { key: "amount", label: this.$t("Funds.Table.Amount"), _style: "" },
        { key: "pay_way", label: this.$t("Funds.Table.FundingSource"), _style: "" },
        { key: "status", label: this.$t("Funds.Table.Status"), _style: "" },
      ];
      return fields;
    },
  },
  created() {},
  components: {
    CDropdown,
    CModal,
    CModalBody,
    CModalHeader,
    CModalFooter,
    Shopping,
  },
};
</script>
<style scoped lang="scss">
/* .box{
    display: flex;
    align-items: center;
  } */
.hed-box {
  .box {
    margin-bottom: 10px;
    .i-group {
      display: flex;
      align-items: center;
      .i-input {
        margin-left: 10px;
        input {
          border: 0;
          outline: 0;
          border-radius: 20px;
          padding: 7px 2px;
          text-indent: 10px;
          border: 1px solid #f1f1f2;
        }
      }
    }
  }
}
.title {
  width: 150px;
}
.wrp {
  display: flex;
  align-items: center;
  // .btn-ghost-info{
  //   color: #000 !important;
  // }
  .form-group {
    margin: 0;
  }
}
.ddd {
  color: #fff;
}
.ds {
  color: #000;
}
.con-box {
  padding-bottom: 9px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.csu-box {
  display: flex;
  align-items: center;
}
.f {
  color: #ff0000;
}
.z {
  // color: #98FA1C;
  color: #00bea4;
}
.visa-box {
  display: flex;
  align-items: center;
}
.icon-box {
  display: flex;
  align-items: center;
  justify-content: start;
  // padding: 0 12px;
}
.tds {
  margin-left: 0px;
}

.s-btn {
  border: 0;
  outline: 0;
  padding: 5px 20px;
  border-radius: 20px;
  background: transparent;
  border: 1px solid #f1f1f2;
}
.s-btn:hover {
  background: #00bea4;
  border-color: #00bea4;
  color: #fff;
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
}
.rcbox{
    width: 78%;
    margin: 0 auto;
    border: 1px solid #ccc;
    background: #fff;
}
</style>